import React, {Dispatch, SetStateAction} from "react";
import {Box, FormControlLabel, FormControl, RadioGroup, Radio} from "@mui/material";
import Grid from '@mui/material/Grid';
import "./SendgridGlobalOption.scss";

export interface ISendgridGlobalOptionProps {
    setGlobalSuppression: Dispatch<SetStateAction<boolean>>;
    globalSuppression: boolean;
    isLoadingOrIsSaving: boolean;
    changed: number;
}
export const SendgridGlobalOption: React.FC<ISendgridGlobalOptionProps> = ({globalSuppression, setGlobalSuppression, isLoadingOrIsSaving, changed}) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setGlobalSuppression(value === "true");
    }

    return (
        <Grid item xs={12} md={12}>
            <p className="title">Global status</p>
            <Grid container direction="column" alignItems="center" justifyContent="center">
                <FormControl>
                    <RadioGroup row style={{alignItems: "center"}} onChange={onChange} value={`${globalSuppression}`} >
                        <FormControlLabel value="true" disabled={isLoadingOrIsSaving} control={<Radio />} label="Unsubscribed" style = {{marginRight: "100px"}}/>
                        <FormControlLabel value="false" disabled={isLoadingOrIsSaving} control={<Radio />} label="Subscribed"/>
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Box className="line-box"></Box>
            <p className="title">Groups status (changed: {changed})</p>
        </Grid>
    );
};