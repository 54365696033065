import * as Blockly from "blockly/core";

export function getExtraBlockState(block: any) {
    if (block.saveExtraState) {
        const state = block.saveExtraState();
        return state ? JSON.stringify(state) : "";
    } else if (block.mutationToDom) {
        const state = block.mutationToDom();
        return state ? Blockly.Xml.domToText(state) : "";
    }
    return "";
}
