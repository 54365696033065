import { Workspace } from "blockly/core/workspace";
import { ELEMENT, ELEMENT_, OPERATION } from "../utils/constants";
import {NamedCondition} from "../utils/types";

let namedConditionsList: Array<NamedCondition> = [];

/**
 * Main function, render start block.
 * @param jsonText Json to transform into block.
 * @param workspace Blockly workspace.
 * @param namedConditions Named conditions list
 * @returns void
 */
export const codeToWorkspace = function (jsonText: string, workspace: Workspace, namedConditions: Array<NamedCondition>): void {
    try {
        namedConditionsList = namedConditions;
        const jsonStructure = JSON.parse(jsonText);
        workspace.clear();
        const block = workspace.newBlock("start") as any;
        block.initSvg();
        block.render();
        buildAndConnect(jsonStructure, block.getInput(ELEMENT).connection);
    } catch (e) {
        console.log("Error generating blocks", e.message, jsonText);
    }
};

/**
 * Recursive method that goes through the entire json(jsonStructure) and is responsible for generating the blocks.
 * @param jsonStructure Json to transform into block.
 * @param parentConnection Connection to parent block.
 * @returns void
 */
export const buildAndConnect = function (jsonStructure: any, parentConnection: any): void {
    if (jsonStructure === null || jsonStructure === undefined) {
        return;
    }

    // Get the block type
    const jsonKeys = Object.keys(jsonStructure);
    const type = getBlockType(jsonKeys, jsonStructure);

    // Create the block and connect it to the parent
    const targetBlock = parentConnection.sourceBlock_.workspace.newBlock(type);
    targetBlock.initSvg();
    targetBlock.render();
    const childConnection = targetBlock.outputConnection;
    parentConnection.connect(childConnection);

    const KEY = jsonKeys[0];

    // Add the necessary inputs to the newly created block and render its child blocks.
    switch (type) {
        case "number":
            targetBlock.setFieldValue(jsonStructure, ELEMENT);
            break;
        case "boolean":
            targetBlock.setFieldValue(String(jsonStructure), ELEMENT);
            break;
        case "string":
            targetBlock.setFieldValue(String(jsonStructure), ELEMENT);
            break;
        case "var":
            targetBlock.setFieldValue(String(jsonStructure.var), ELEMENT);
            break;
        case "keyValue":
            targetBlock.setFieldValue(KEY, "KEY");
            targetBlock.setFieldValue(jsonStructure[KEY], ELEMENT);
            break;
        case "in":
            for (const i in jsonStructure[KEY]) {
                const elementConnection = targetBlock.getInput(`${ELEMENT_}${i}`).connection;
                buildAndConnect(jsonStructure[KEY][i], elementConnection);
            }
            break;
        case "not":
            renderChildren(targetBlock, ELEMENT, jsonStructure[KEY]);
            break;
        case "comparison":
        case "between":
        case "arrayOperations":
            targetBlock.setFieldValue(KEY, OPERATION);
            for (const i in jsonStructure[KEY]) {
                renderChildren(targetBlock, `${ELEMENT_}${i}`, jsonStructure[KEY][i]);
            }
            break;
        case "logical":
            targetBlock.setFieldValue(KEY, OPERATION);
            targetBlock.updateShape_(jsonStructure[KEY].length);
            for (const i in jsonStructure[KEY]) {
                renderChildren(targetBlock, `${ELEMENT_}${i}`, jsonStructure[KEY][i]);
            }
            break;
        case "final":
            // send value [true | false]
            targetBlock.setFieldValue(`${jsonStructure.send}`, "SEND");

            // name_status [status | status_root]
            const nameStatus = jsonKeys.find(item => item === "status" || item === "status_root");
            targetBlock.setFieldValue(nameStatus, "NAME_STATUS");

            targetBlock.setFieldValue(jsonStructure[nameStatus as any], "STATUS");
            const len = jsonKeys.length - 2;
            targetBlock.updateShape_(len);

            const aux = jsonKeys.filter(item => item !== "send" && item !== nameStatus);

            for (let i = 0; i < len; i++) {
                renderChildren(targetBlock, `${ELEMENT_}${i}`, {[aux[i]]: jsonStructure[aux[i]]});
            }
            break;
        case "maxmin":
            targetBlock.setFieldValue(jsonKeys[0], ELEMENT);
            targetBlock.updateShape_(jsonStructure[KEY].length);
            for (const i in jsonStructure[KEY]) {
                renderChildren(targetBlock, `${ELEMENT_}${i}`, jsonStructure[KEY][i]);
            }
            break;
        case "and":
        case "or":
        case "cat":
        case "substr":
            targetBlock.updateShape_(jsonStructure[KEY].length);
            for (const i in jsonStructure[KEY]) {
                renderChildren(targetBlock, `${ELEMENT_}${i}`, jsonStructure[KEY][i]);
            }
            break;
        case "lodash":
            targetBlock.setFieldValue(jsonKeys[0].replace("lodash.",""), ELEMENT);
            targetBlock.updateShape_(jsonStructure[KEY].length);
            for (const i in jsonStructure[KEY]) {
                renderChildren(targetBlock, `${ELEMENT_}${i}`, jsonStructure[KEY][i]);
            }
            break;
        case "object":
            targetBlock.updateShape_(jsonKeys.length);
            for (let i = 0; i < jsonKeys.length; i++) {
                targetBlock.setFieldValue(String(jsonKeys[i]), `key_${ELEMENT_}${i}`);
                renderChildren(targetBlock, `${ELEMENT_}${i}`, jsonStructure[jsonKeys[i]]  );
            }
            break;
        case "if":
            for (let i = 0; i < 3; i++) {
                renderChildren(targetBlock, `${ELEMENT_}base_${i}`, jsonStructure[KEY][i]);
            }
            const rest = jsonStructure[KEY].length - 3;
            if (rest) {
                targetBlock.updateShape_(rest);
                for (let i = 0; i < rest; i++) {
                    renderChildren(targetBlock, `${ELEMENT_}${i}`, jsonStructure[KEY][i+3]);
                }
            }
            break;
        case "namedCondition":
            targetBlock.setFieldValue(jsonKeys[0], "CONDITION");
            const item = namedConditionsList.find((val: NamedCondition) => val.name === jsonKeys[0]);
            const LEN = item && item.body?.length > 1 ? item?.body.length - 1 : 0;
            targetBlock.updateShape_(LEN, item?.body);
            for (let i = 0; i < LEN; i++) {
                const elementConnection = targetBlock.getInput(
                    `${ELEMENT_}${i}`
                )?.connection;
                if (elementConnection) {
                    buildAndConnect(jsonStructure[KEY][i], elementConnection);
                }
            }
            break;
        case "array":
            targetBlock.updateShape_(jsonStructure.length);
            for (const i in jsonStructure) {
                renderChildren(targetBlock, `${ELEMENT_}${i}`, jsonStructure[i]);
            }
            break;
    }
};

// AUXILIARY FUNCTIONS
/**
 * Get the block type.
 * @param jsonKeys Keys of json.
 * @param jsonStructure Json to transform into block.
 * @returns void
 */
const getBlockType = function (
    jsonKeys: Array<string>,
    jsonStructure: any
): string {
    const keyIn0 = jsonKeys[0];
    const jsonValues = Object.values(jsonStructure) as Array<any>;
    let valType: any = typeof jsonStructure;

    if (valType === "string" || valType === "number" || valType === "boolean" ) {
        return valType;
    }

    switch (keyIn0) {
        case "if":
        case "and":
        case "or":
        case "in":
        case "cat":
        case "substr":
            return keyIn0;
        case "max":
        case "min":
            return "maxmin";
        case "!":
            return "not";
        case "var":
            if (jsonValues.length === 1) {
                return "var";
            }
            break;
        case "none":
        case "all":
        case "some":
            return "arrayOperations";
        case "!=":
        case "!==":
        case "!!":
        case "==":
        case "===":
            return "logical";
        case ">":
        case ">=":
        case "<":
        case "<=":
            if (jsonValues[0].length === 3) {
                if (keyIn0 === "<=" || keyIn0 === "<") {
                    return "between";
                }
            } else {
                return "comparison";
            }
            break;
        default:
            if (jsonStructure instanceof Array) {
                return "array";
            } else {

                if (keyIn0.startsWith("lodash.")) {
                    return "lodash";
                }

                if (namedConditionsList.find((item: NamedCondition) => item.name === keyIn0)) {
                    return "namedCondition";
                }

                if (jsonKeys.length === 1 && typeof jsonStructure[keyIn0] === "string") {
                    return "keyValue";
                }

                if (jsonKeys.find(item => item === "send")) {
                    return "final";
                }
            }
    }

    return valType;
};

/**
 * Render blocks children.
 * @param block Current block.
 * @param name Child name.
 * @param jsonStructure Json to transform into block.
 * @returns void
 */
const renderChildren = function (
    block: any,
    name: string,
    jsonStructure: any
): void {
    const elementConnection = block.getInput(name).connection;
    buildAndConnect(jsonStructure, elementConnection);
};
