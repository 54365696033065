import React, {Dispatch, SetStateAction, useState} from "react";
import { useEffect, useRef } from "react";
import * as _ from "lodash";
import {FUNCTIONS, FUNCTIONS_SELECT, RESERVED_WORDS} from "./components/utils/constants";
import {FrontendConfiguration} from "../../../../Configuration";

// BLOCKLY
import Blockly from "blockly/core";
import "./BlocklyComponent.scss";
import { jsonGenerator } from "./components/generator/generator";
import locale from "blockly/msg/en";
import "blockly/blocks";

// JSON
import * as beautify from "json-beautify";
import {NamedCondition} from "./components/utils/types";
import {getAllKeyInText} from "./components/utils/utils";

Blockly.setLocale(locale);


export interface IBlocklyComponentProps {
    setCodeBlockly: Dispatch<SetStateAction<string>>;
    setCodeEditor: Dispatch<SetStateAction<string>>;
    codeEditor: string;
    codeBlockly: string;
    namedConditionsList: Array<NamedCondition>;
    initialXml: any;
    fetchers: Array<string>;
    setErrorsList: Dispatch<SetStateAction<Array<string>>>;
    errors: Array<string>;
}
export const BlocklyComponent: React.FC<IBlocklyComponentProps> = ({codeBlockly, setCodeBlockly, codeEditor, setCodeEditor, namedConditionsList, initialXml, fetchers, setErrorsList, errors, ...props}) => {
    const blocklyDiv = useRef(null);
    const toolbox = useRef(null);
    let primaryWorkspace = useRef(null);
    const [validList] = useState(_.union(_.map(namedConditionsList, "name"), FUNCTIONS, RESERVED_WORDS, FUNCTIONS_SELECT));
    const [invalidJson, setInvalidJSON] = useState("");

    const generateCode = (isDelete = false) => {
        const code = jsonGenerator.workspaceToCode(primaryWorkspace.current as any);
        try {
            const deletedBlocks = isDelete && code === "null";
            if ((code !== undefined && JSON.parse(code)) || deletedBlocks) {
                // @ts-ignore
                const codeBeautify = deletedBlocks ? "{}" : beautify(JSON.parse(code), null, 2, 80);
                setCodeBlockly(codeBeautify);
                setCodeEditor(codeBeautify);
                window.parent.postMessage({condition: codeBeautify}, FrontendConfiguration.getLegacyAngularAppUrl());
            }
        } catch (e) {
            console.log("Invalid JSON, generateCode,", e.message as any);
        }
    };

    const validateCondition = () => {
        const keysInCondition = getAllKeyInText(codeEditor);
        _.remove(keysInCondition, (key: string) => _.startsWith(key, "lodash."));
        const invalidNCList= _.uniq(_.differenceBy(keysInCondition, validList));
        let namedConditions = namedConditionsList.filter(item => keysInCondition.find(key => item.name === key));
        const missingFetchers = _.uniq(_.differenceBy(_.flatten(namedConditions.map(item => item.fetchers)), fetchers));
        setErrorsList(invalidJson? [`Invalid condition: ${invalidJson}`] : [...invalidNCList.map(item => `invalid: ${item}`), ...missingFetchers.map(item => `missing: ${item} fetcher`)]);
    }

    const interpretCode = () => {
        try {
            if (JSON.parse(codeEditor) && primaryWorkspace?.current) {
                if (codeBlockly !== codeEditor) {
                    jsonGenerator["codeToWorkspace"](codeEditor, primaryWorkspace.current, namedConditionsList);
                }

                setInvalidJSON("");
            }
        } catch (e) {
            console.log("Invalid JSON, interpretCode,", e.message);
            setInvalidJSON(e.message);
        }
    };

    useEffect(() => {
        interpretCode();
        validateCondition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeEditor]);

    useEffect(() => {
        validateCondition()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invalidJson])

    useEffect(() => {
        primaryWorkspace.current = Blockly.inject(blocklyDiv.current as any, {
            toolbox: toolbox.current as any,
            readOnly: false,
            trashcan: true,
            sounds: false,
            css: true,
            move: {
                scrollbars: true,
                drag: true,
                wheel: true,
            }
        }) as any;

        if (initialXml && primaryWorkspace.current) {
            Blockly.Xml.domToWorkspace(
                Blockly.utils.xml.textToDom(initialXml),
                primaryWorkspace.current as any
            );
        }

        primaryWorkspace.current.addChangeListener((event: any) => {
            if (event.type === "change" || event.type === "move" || event.type === "delete") {
                generateCode(event.type === "delete");
            }
        });

        jsonGenerator["codeToWorkspace"](codeEditor, primaryWorkspace.current, namedConditionsList);
        return () => {
            primaryWorkspace.current.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <div ref={blocklyDiv} id="blocklyDiv" />
            <div style={{ display: "none" }} ref={toolbox}>
                {props.children}
            </div>
        </React.Fragment>
    );
}
