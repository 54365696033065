import Blockly from "blockly/core";
import {ELEMENT_} from "../utils/constants";

const NamedConditionMutator = {
    itemCount_: 0,
    appendValueInput: function (val: any) {
        return { appendField: function (val: any) {} };
    },
    moveInputBefore: function (val1: any, val2: any) {},
    getInputTargetBlock: function (val: any) {
        return { dispose: function (val: any) {} };
    },
    removeInput: function (val: any) {},

    mutationToDom: function () {
        const container = Blockly.utils.xml.createElement("mutation");
        container.setAttribute("items", `${this.itemCount_}`);
        return container;
    },

    domToMutation: function (xmlElement: any) {
        const targetCount = parseInt(xmlElement.getAttribute("items"), 10) || 0;
        this.updateShape_(targetCount);
    },

    saveExtraState: function () {
        return {
            itemCount: this.itemCount_,
        };
    },

    loadExtraState: function (state: any) {
        this.updateShape_(state["itemCount"]);
    },

    updateShape_: function (numInputs: number, list = []) {
        this.removePart_();
        this.addPart_(numInputs, list)
    },

    newInputs: function (len: number, list = []) {
        this.removePart_();
        this.addPart_(len, list);
    },

    addPart_: function (len: number, list = []) {
        for (let i = 0; i < len; i++) {
            this.appendValueInput(`${ELEMENT_}${i}`).appendField(` ${list[i]}`);
            this.moveInputBefore(`${ELEMENT_}${i}`, null);
        }
        this.itemCount_ = len;
    },

    removePart_: function () {
        for (let i = 0; i < this.itemCount_; i++) {
            const inputNameToDelete = `${ELEMENT_}${i}`;
            const subStructure = this.getInputTargetBlock(inputNameToDelete);
            if (subStructure) {
                subStructure.dispose(true);
            }
            this.removeInput(inputNameToDelete);
        }
        this.itemCount_ = 0;
    },
};

export default NamedConditionMutator;

