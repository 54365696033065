import {useState} from "react";
import {verifyEmail} from "../../../services/notifications.service";

export function useEmailValidatorView() {
    // Flags
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //Variables
    const [email, setEmail] = useState<string>("");
    const [timeout, setTimeout] = useState<number>(30);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [validation, setValidation]= useState<{ status: string, description:string } | null>(null);

    const validate = async() => {
        try{
            const res = await verifyEmail(email, timeout);
            setValidation(res.data);
        }
        catch(e) {
            setValidation({status: "500", description: "Connection error"});
        }
    }

    return {
        isLoading,
        setIsLoading,
        validate,
        validation,
        email,
        setEmail,
        timeout,
        setTimeout,
        isEmailValid,
        setIsEmailValid
    };
}