import * as Blockly from "blockly";
import { codeToWorkspace, buildAndConnect } from "./interpret";
//import { Block } from "blockly";
import { NewBlock, NewGenerator } from "../utils/types";
import { ELEMENT, ELEMENT_, OPERATION } from "../utils/constants";
export const jsonGenerator = new Blockly.Generator("JSON") as NewGenerator;

/**
 * This variable is used to indicate the precedence since recursion is used to generate the code..
 */
jsonGenerator.PRECEDENCE = 0;

/**
 * Override the scrub_ method to make it work with JSON.
 */
// jsonGenerator["scrub_"] = function (block: Block, code: string, thisOnly?: boolean): string {
//     const nextBlock = block.nextConnection && block.nextConnection.targetBlock();
//     if (nextBlock && !thisOnly) {
//         return code + "," + jsonGenerator.blockToCode(nextBlock);
//     }
//     return code;
// };

/**
 * These two functions are used to transform from code to block.
 */
jsonGenerator.codeToWorkspace = codeToWorkspace;

jsonGenerator.buildAndConnect = buildAndConnect;

/**
 * All of the following functions are used to transform blocks to code.
 * @param block Current block.
 * @returns codeString block to string
 * @returns jsonGenerator.PRECEDENCE block precedence
 */
jsonGenerator.start = function (block: NewBlock): [string, number] {
    const textValue = jsonGenerator.valueToCode(
        block,
        ELEMENT,
        jsonGenerator.PRECEDENCE
    );
    const codeString = textValue ? `${textValue}` : "null";
    return [codeString, jsonGenerator.PRECEDENCE];
};

jsonGenerator.and = function (block: NewBlock): [string, number] {
    return blockWithList(block, "and");
};

jsonGenerator.array = function (block: NewBlock): [string, number] {
    const codeString = `[${childrenBlocks(block)}]`;
    return [codeString, jsonGenerator.PRECEDENCE];
};

jsonGenerator.or = function (block: NewBlock): [string, number] {
    return blockWithList(block, "or");
};

jsonGenerator.string = function (block: NewBlock): [string, number] {
    const textValue = block.getFieldValue(ELEMENT);
    const codeString = `"${textValue}"`;
    return [codeString, jsonGenerator.PRECEDENCE];
};

jsonGenerator.boolean = function (block: NewBlock): [string, number] {
    const textValue = block.getFieldValue(ELEMENT);
    const codeString = `${textValue}`;
    return [codeString, jsonGenerator.PRECEDENCE];
};

jsonGenerator.number = function (block: NewBlock): [string, number] {
    const textValue = block.getFieldValue(ELEMENT);
    const codeString = `${textValue}`;
    return [codeString, jsonGenerator.PRECEDENCE];
};

jsonGenerator.not = function (block: NewBlock): [string, number] {
    const valueCode = jsonGenerator.valueToCode(block, ELEMENT, jsonGenerator.PRECEDENCE);
    const codeString = `{"!":${valueCode ? valueCode : null}}`;
    return [codeString, jsonGenerator.PRECEDENCE];
};

jsonGenerator.var = function (block: NewBlock): [string, number] {
    const textValue = block.getFieldValue(ELEMENT);
    const codeString = `{"var":"${textValue}"}`;
    return [codeString, jsonGenerator.PRECEDENCE];
};

jsonGenerator.keyValue = function (block: NewBlock): [string, number] {
    const textValue = block.getFieldValue(ELEMENT);
    const textKey = block.getFieldValue("KEY");
    const codeString = `{"${textKey}":"${textValue}"}`;
    return [codeString, jsonGenerator.PRECEDENCE];
};

jsonGenerator.lodash = function (block: NewBlock): [string, number] {
    const textValue = block.getFieldValue(ELEMENT);
    return blockWithList(block, `lodash.${textValue}`);
};

jsonGenerator.final = function (block: NewBlock): [string, number] {
    const send = block.getFieldValue("SEND");
    const nameStatus = block.getFieldValue("NAME_STATUS");
    const status = block.getFieldValue("STATUS");
    let code = {"send": send === "true" , [nameStatus]: status};

    const children = blockWithList(block, "aux");

    for (const child of JSON.parse(children[0]).aux) {
        code = {...code, ...child};
    }
    const codeString = JSON.stringify(code);
    return [codeString, jsonGenerator.PRECEDENCE];
};

jsonGenerator.arrayOperations = function (block: NewBlock): [string, number] {
    const operation = block.getFieldValue(OPERATION);
    return blockWithList(block, operation);
};

jsonGenerator.comparison = function (block: NewBlock): [string, number] {
    const operation = block.getFieldValue(OPERATION);
    return blockWithList(block, operation);
};

jsonGenerator.between = function (block: NewBlock): [string, number] {
    const operation = block.getFieldValue(OPERATION);
    return blockWithList(block, operation);
};

jsonGenerator.logical = function (block: NewBlock): [string, number] {
    const operation = block.getFieldValue(OPERATION);
    return blockWithList(block, operation);
};

jsonGenerator.namedCondition = function (block: NewBlock): [string, number] {
    const textValue = block.getFieldValue("CONDITION");
    return blockWithList(block, textValue);
};

jsonGenerator.if = function (block: NewBlock): [string, number] {
    return blockWithList(block, "if", 3);
};

jsonGenerator.in = function (block: NewBlock): [string, number] {
    return blockWithList(block, "in");
};

jsonGenerator.cat = function (block: NewBlock): [string, number] {
    return blockWithList(block, "cat");
};

jsonGenerator.maxmin = function (block: NewBlock): [string, number] {
    const textValue = block.getFieldValue(ELEMENT);
    return blockWithList(block, textValue);
};

jsonGenerator.substr = function (block: NewBlock): [string, number] {
    return blockWithList(block, "substr", 2);
};

jsonGenerator.object = function (block: NewBlock): [string, number] {
    const values = [];

    for (let i = 0; i < block.itemCount_; i++) {
        const valueCode = jsonGenerator.valueToCode(block, `${ELEMENT_}${i}`, jsonGenerator.PRECEDENCE);
        const valueKey = block.getFieldValue(`key_${ELEMENT_}${i}`);
        values.push(`"${valueKey}": ${valueCode ? valueCode : "null"}`);

    }
    values.join(",");

    return [`{${values}}`, jsonGenerator.PRECEDENCE]
};

/**
 * Returns the code of block with its children.
 * @param block Current block.
 * @param name Block name
 * @param min Some blocks have a minimum of inputs. This value indicates the number of these.
 * @returns codeString block to string
 * @returns jsonGenerator.PRECEDENCE block precedence
 */
const blockWithList = function (block: NewBlock, name: string, min = 0): [string, number] {
    const codeString = `{"${name}":[${childrenBlocks(block, min)}]}`;
    return [codeString, jsonGenerator.PRECEDENCE];
};

/**
 * Returns the code of the child blocks in string format.
 * @param block Current block.
 * @param min Some blocks have a minimum of inputs. This value indicates the number of these.
 * @returns string
 */
const childrenBlocks = function (block: NewBlock, min = 0): string {
    const values = [];

    for (let i = 0; i < min; i++) {
        const valueCode = jsonGenerator.valueToCode(block, `${ELEMENT_}base_${i}`, jsonGenerator.PRECEDENCE);
        values.push(valueCode ? valueCode : "null");
    }

    for (let i = 0; i < block.itemCount_; i++) {
        const valueCode = jsonGenerator.valueToCode(block, `${ELEMENT_}${i}`, jsonGenerator.PRECEDENCE);

        if (block.type === "namedCondition"){
            if (valueCode) {
                values.push(valueCode);
            }
        } else {
            values.push(valueCode ? valueCode : "null");
        }
    }

    return values.join(",");
};
