import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from "./App";
import {FrontendConfiguration} from "./Configuration";
import {ImplicitFlow} from "@vacasa/react-components-lib";

if (FrontendConfiguration.isLocalEnv()) {
    console.log(process.env);
}

const params = new URLSearchParams(window.location.search);
const access_token: string | null = params.get("token");
if (access_token) {
    localStorage.setItem("access_token", access_token);
}

const implicitFlowConfig = {
    ...FrontendConfiguration.implicitFlowConfig(),
    followRedirect: true,
};

window.addEventListener(
    "message",
    (event) => {
        if (event.origin !== FrontendConfiguration.getLegacyAngularAppUrl()) {
            return;
        }
        const permissions = event.data.permissions;

        if (event.data.permissions) {
            localStorage.setItem("permissions", permissions);
        }
    },
    false
);

ReactDOM.render(
    <React.StrictMode>
        <ImplicitFlow {...implicitFlowConfig}>
            <App />
        </ImplicitFlow>
    </React.StrictMode>,
    document.getElementById("root")
);
