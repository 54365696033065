import Blockly from "blockly/core";
import { ELEMENT_ } from "../utils/constants";

const PlusMinusMutator = {
    length: 0,
    itemCount_: 0,
    appendValueInput: function (val: any) {},
    moveInputBefore: function (val1: any, val2: any) {},
    getInputTargetBlock: function (val: any) {
        return { dispose: function (val: any) {} };
    },
    removeInput: function (val: any) {},

    mutationToDom: function () {
        const container = Blockly.utils.xml.createElement("mutation");
        container.setAttribute("items", `${this.length}`);
        return container;
    },

    domToMutation: function (xmlElement: any) {
        const targetCount = parseInt(xmlElement.getAttribute("items"), 10);
        this.updateShape_(targetCount);
    },

    saveExtraState: function () {
        return {
            itemCount: this.length,
        };
    },

    loadExtraState: function (state: any) {
        this.updateShape_(state["itemCount"]);
    },

    updateShape_: function (targetCount: number) {
        while (this.length < targetCount) {
            this.addPart_();
        }
        while (this.length > targetCount) {
            this.removePart_();
        }
    },

    plus: function (max?: number) {
        if (!max || this.length !== max) {
            this.addPart_();
        }
    },

    minus: function () {
        if (this.length === 0) {
            this.itemCount_ = 0;
            return;
        }
        this.removePart_();
    },

    addPart_: function () {
        const lastIndex = this.length++;
        this.itemCount_ = lastIndex + 1;
        this.appendValueInput(`${ELEMENT_}${lastIndex}`);
        this.moveInputBefore(`${ELEMENT_}${lastIndex}`, null);
    },

    removePart_: function () {
        const val = this.length--;
        this.itemCount_ = val - 1;
        const inputNameToDelete = `${ELEMENT_}${this.itemCount_}`;
        const subStructure = this.getInputTargetBlock(inputNameToDelete);
        if (subStructure) {
            subStructure.dispose(true);
        }
        this.removeInput(inputNameToDelete);
    },
};

export default PlusMinusMutator;
