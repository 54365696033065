export const ELEMENT = "element";
export const ELEMENT_ = `${ELEMENT}_`;
export const OPERATION = "OPERATION";

export const FUNCTIONS = [
    "if",
    "not",
    "namedCondition",
    "lodash",
    "and",
    "or",
    "string",
    "number",
    "var",
    "keyValue",
    "array",
    "boolean",
    "object",
    "final",
    "logical",
    "comparison",
    "between",
    "in",
    "cat",
    "maxmin",
    "substr",
    "arrayOperations",
];

export const FUNCTIONS_SELECT = [
    "some",
    "all",
    "none",
    "max",
    "min"
];

export const RESERVED_WORDS = [
    "evaluate",
    "send",
    "status_root",
    "status",
    "method",
    "params",
    "from_name",
    "from_email",
    "identifier",
    "reply_to",
    "reply_to_email",
    "from_phone_number",
    "to_name",
    "to_email",
    "to_lang",
    "sending_param",
    "type"
];

export const namedConditionsInCode = [
    {
        "name": "encodeURIComponent",
        "body": [
            "URI",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "nl2br",
        "body": [
            "text",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "addDays",
        "body": [
            "last_night",
            "days",
            "format",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "md5",
        "body": [
            "value",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "dueDate",
        "body": [
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "dateIsBefore",
        "body": [
            "day1",
            "day2",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "dateDiffToToday",
        "body": [
            "day",
            "format",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "sameDate",
        "body": [
            "day1",
            "day2",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "setDateAttributes",
        "body": [
            "date",
            "attr = {}",
            "format",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "getCheckinDate",
        "body": [
            "firstNight",
            "unitCheckinTime",
            "defaultTime",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "getCheckoutDate",
        "body": [
            "lastNight",
            "unitCheckoutTime",
            "lateCheckout",
            "defaultTime",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "isPaymentByCheckAllowed",
        "body": [
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "isConfirmationEmailFlags",
        "body": [
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "hasInquiryWithin",
        "body": [
            "time",
            "format",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "dateDiff",
        "body": [
            "date1",
            "date2",
            "format",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "dateIsBetween",
        "body": [
            "date",
            "date1",
            "date2",
            "inclusivity",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "isSomeUnitAvailable",
        "body": [
            "availability",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "regex",
        "body": [
            "value",
            "regex",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "convert24to12",
        "body": [
            "value",
            "format",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "textContainsWords",
        "body": [
            "text",
            "words",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "textContainsSentences",
        "body": [
            "text",
            "sentences",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "shortenUrl",
        "body": [
            "url",
            "isDynamic",
            "TEST"
        ],
        "custom": true
    },
    {
        "name": "dayOfTheWeek",
        "body": [
            "date",
            "TEST"
        ],
        "custom": true
    }
];