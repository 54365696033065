import ReactDOM from "react-dom";
import React from "react";
import * as Blockly from "blockly/core";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {getExtraBlockState} from "../helpers/serializationHelper";
import {NamedCondition} from "../utils/types";
import * as _ from "lodash";


export default class AutocompleteField extends Blockly.Field {
  div_: any;
  SERIALIZABLE = true
  namedConditionsList: Array<NamedCondition> = [];
  fetchers: Array<string> = [];

  constructor(props: any) {
    super(props);
    this.namedConditionsList = props.namedConditionsList;
    this.fetchers = props.fetchers;
  }

  static fromJson(options: any) {
    return new this(options["text"]);
  }

  showEditor_() {
    this.div_ = Blockly.DropDownDiv.getContentDiv() as any;
    ReactDOM.render(this.render(), this.div_);
    Blockly.DropDownDiv.showPositionedByField(this, this.dropdownDispose_.bind(this));
  }

  dropdownDispose_() {
    ReactDOM.unmountComponentAtNode(this.div_);
  }

  onChange(event: any, value: any) {

    if (!value?.label) {
      return;
    }

    this.setValue(value.label);

    const block = this.getSourceBlock() as any;

    if (block.isInFlyout) {
      return;
    }
    Blockly.Events.setGroup(true);
    const oldExtraState = getExtraBlockState(block);
    const item = this.namedConditionsList.find((val: NamedCondition) => val.name === value.label);
    block.newInputs(item.body.length > 1 ? item.body.length - 1 : 0, item.body);
    const newExtraState = getExtraBlockState(block);

    if (oldExtraState !== newExtraState) {
      Blockly.Events.fire(new Blockly.Events.BlockChange(block, "mutation", null, oldExtraState, newExtraState));
    }
    Blockly.Events.setGroup(false);
  }

  render() {
    const list: Array<{label: string, disabled: boolean}> = this.namedConditionsList.map((item: NamedCondition) =>
        {
          const difference = _.difference(item.fetchers, this.fetchers);
          const disabled = difference.length > 0;
          return {label: item.name, disabled: disabled};
        }
    );
    return (
        <div style={{ color: "#fff", margin: "10px", height: 250}}>
          <Autocomplete
              disablePortal
              id="named-conditions-autocomplete"
              options={list}
              getOptionDisabled={(option) => option.disabled}
              sx={{ width: 300, height: 50 }}
              renderInput={(params) => <TextField {...params} label="Search" />}
              onChange={(e: any, v: any) => this.onChange(e, v)}
              ListboxProps={{ style: { maxHeight: 180 } }}
          />
        </div>
    );
  }
}

Blockly.fieldRegistry.register("field_auto_complete", AutocompleteField);
