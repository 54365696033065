import * as Blockly from "blockly/core";
import {getExtraBlockState} from "../helpers/serializationHelper";

export default class MinusButtonField extends Blockly.Field {
  SERIALIZABLE = true;

  static fromJson(options: any): any {
    return new this(options["text"]);
  }

  showEditor_(e: any): void {
    super.showEditor_(e);
    this.onClick(e);
  }

  onClick(e: any): void {
    const block = this.getSourceBlock() as any;

    if (block.isInFlyout) {
      return;
    }
    Blockly.Events.setGroup(true);
    const oldExtraState = getExtraBlockState(block);

    block.minus(e);

    const newExtraState = getExtraBlockState(block);

    if (oldExtraState !== newExtraState) {
      Blockly.Events.fire(new Blockly.Events.BlockChange(block, "mutation", null, oldExtraState, newExtraState));
    }
    Blockly.Events.setGroup(false);
  }
}

Blockly.fieldRegistry.register("field_minus_button", MinusButtonField);
