import React from "react";
import {Box} from "@mui/material";
import "./SendgridInstructions.scss";

export const SendgridInstructions = () => {
    return (
        <Box className="instructions-box">
            <b>Global status:</b>

            <li>
                <b>Unsubscribed:</b> The user does not receive any email from this account.
            </li>
            <li>
                <b>Subscribed:</b> The user receives emails from this account, as long as the identifier does not belong to a group from which he has unsubscribed.
            </li>

            <Box className="line-box-2"></Box>

            <b>Groups status:</b>
            <li>
                <b>Unsubscribed:</b> The user does not receive emails from identifies that belong to these groups.
            </li>
            <li>
                <b>Subscribed:</b> The user receive emails from identifies that belong to these groups.
            </li>
        </Box>
    );
};