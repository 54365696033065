import React, {useEffect, useRef, useState} from "react";

// BLOCKLY
import * as Blockly from "blockly/core";
import {ELEMENT, FUNCTIONS, namedConditionsInCode} from "./Blockly/components/utils/constants";
import AutocompleteField from "./Blockly/components/fields/AutocompleteField";
import {plusMinusMutatorFunctionsNamedConditions} from "./Blockly/components/blocks/customblocks";
import {BlocklyComponent} from "./Blockly/BlocklyComponent";
import { Block } from "./Blockly";
import "./Blockly/components/blocks/customblocks";
import "./Blockly/components/generator/generator";

// TEXT EDITOR
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';

// OTHERS
import "./ConditionBlocklyView.scss";
import {FrontendConfiguration} from "../../../Configuration";
import {HeaderModalCondition} from "../../../components/HeaderModalCondition/HeaderModalCondition";
import {Alert} from "../../../components/Alert/Alert";
import {NamedCondition} from "./Blockly/components/utils/types";
import {Panel, PanelGroup, PanelResizeHandle} from "react-resizable-panels";


function TXConditionView() {
    const [previousCode, setPreviousCode] = useState("");
    const [codeEditor, setCodeEditor] = useState("");
    const [codeBlockly, setCodeBlockly] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const hasEventArrivedRef = useRef(false);
    const [namedConditionsList, setNamedContionsList] = useState([]);
    const [errors, setErrors] = useState<Array<string>>([]);
    const [currentFetcherList, setCurrentFetcherList] = useState([""]);
    const [height, setHeight] = useState(0);
    const alertDiv = useRef(null) as any;

    useEffect(() => {
        window.addEventListener("message", handleEvent, false);
        return () => {
            window.removeEventListener("message", handleEvent);
            hasEventArrivedRef.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (errors.length > 0) {
            setHeight(alertDiv?.current?.clientHeight);
        } else {
            setHeight(0);
        }
    }, [alertDiv?.current?.clientHeight, errors]);

    const handleEvent = (event: MessageEvent) => {
        if (event.origin !== FrontendConfiguration.getLegacyAngularAppUrl()) {
            return;
        }
        if (!hasEventArrivedRef.current) {
            const list: Array<NamedCondition> = [...event.data.namedConditionsList, ...namedConditionsInCode].map(item => ({...item, fetchers: item.fetchers?.[0] ? item.fetchers : []}));
            hasEventArrivedRef.current = true;
            const code = event.data.condition === "" ? "{}" : event.data.condition;
            setCodeEditor(code);
            setPreviousCode(code);
            setNamedContionsList(list);
            createNamedConditionBlock(list, event.data.currentFetchers);
            setCurrentFetcherList(event.data.currentFetchers);
            setIsLoading(false);
            window.parent.postMessage({hasEventArrived: true}, FrontendConfiguration.getLegacyAngularAppUrl());
        }
    }

    const blocklySide = () => {
        return (
            <BlocklyComponent
            codeBlockly={codeBlockly}
            setCodeBlockly={setCodeBlockly}
            codeEditor={codeEditor}
            setCodeEditor={setCodeEditor}
            namedConditionsList={namedConditionsList}
            initialXml={`<xml xmlns="http://www.w3.org/1999/xhtml"><Block type="start"></Block></xml>`}
            setErrorsList={setErrors}
            fetchers={currentFetcherList}
            errors={errors}
        >
            {FUNCTIONS.map((item: string) => <Block key={item} type={item}></Block>)}
        </BlocklyComponent>);
    }

    const editorSide = () => {
        return  (
            <CodeMirror
                id="condition-blockly-editor"
                value={codeEditor}
                height={`calc((100vh - 80px) - ${height}px)`}
                extensions={[javascript({})]}
                onChange={(value: string) => setCodeEditor(value)}
                style={{overflow: "auto"}}
                autoCorrect={"false"}
                autoFocus={false}
            />
        );
    }

    const alerts = () => {
        return (
            <div ref={alertDiv} style={{display:"flex", justifyContent:"center", width:"92%"}}>
                <div style={{width: "90%"}}>
                    <Alert type={"error" as any} message={"Errors found:"} setMessage={() => {}} noTimeout={true}>
                        <div>
                            <ul>
                                {errors.map(item => <li key={item}>{item}</li>)}
                            </ul>
                        </div>
                    </Alert>
                </div>
            </div>
        );
    }

    const createNamedConditionBlock = (list: Array<NamedCondition>, fetchers: Array<string>) => {
        Blockly.Blocks["namedCondition"] = {
            init: function () {
                this.setColour("#6BA342");
                this.setOutput(true, [ELEMENT]);
                this.setInputsInline(true);
                this.appendDummyInput()
                    .appendField("condition")
                    .appendField(new AutocompleteField({namedConditionsList: list, fetchers: fetchers}), "CONDITION");
            },
            ...plusMinusMutatorFunctionsNamedConditions
        };
    }

    const closeModal = () => {
        setIsLoading(true);
        window.removeEventListener("message", handleEvent);
        window.parent.postMessage({closeModal: true}, FrontendConfiguration.getLegacyAngularAppUrl());
        hasEventArrivedRef.current = false;
    }

    const resetToPreviousCode = () => {
        setCodeEditor(previousCode);
    }

    return (
        <div>
            <HeaderModalCondition onClick={closeModal} resetToPreviousCode={resetToPreviousCode} />
            {!isLoading ?
                <div>
                    <PanelGroup direction="horizontal">
                        <Panel defaultSize={70} minSize={30} maxSize={70}>
                            <div>
                                {blocklySide()}
                            </div>
                        </Panel>
                        <PanelResizeHandle className="ResizeHandleOuter">
                            <div className="ResizeHandleInner">
                                <svg className="Icon" viewBox="0 0 24 24">
                                    <path
                                        fill="white"
                                        d="M18,16V13H15V22H13V2H15V11H18V8L22,12L18,16M2,12L6,16V13H9V22H11V2H9V11H6V8L2,12Z"
                                    />
                                </svg>
                            </div>
                        </PanelResizeHandle>
                        <Panel defaultSize={30} minSize={30} maxSize={70}>
                            <div style={{height: "calc(100vh - 80px)", border: "solid 1px #CCCCCC"}}>
                                {errors.length > 0 && alerts()}
                                {editorSide()}
                            </div>
                        </Panel>
                        <PanelResizeHandle />
                    </PanelGroup>
                </div>
                :
                <div>is loading ...</div>
            }
        </div>
    );
}

export default TXConditionView;
