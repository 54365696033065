import React, {SetStateAction, Dispatch} from "react";
import {Box, Button, Grid} from "@mui/material";
import "./HeaderClassic.scss";

export interface IHeaderClassicProps {
    title: string; //Sendgrid Subscription Manager (homeowner)
    setIsSaving?: Dispatch<SetStateAction<boolean>>;
    isDisabledButton?: boolean;
    onClick?: any;
}
export const HeaderClassic: React.FC<IHeaderClassicProps> = ({title, setIsSaving, isDisabledButton, onClick}) => {
    return (
        <Box className="header-classic">
            <Grid container>
                <Grid item xs={8} md={11}>
                    <Box className="header-classic-box">
                        <label>{title}</label>
                    </Box>
                </Grid>
                <Grid item xs={4} md={1}>
                    { setIsSaving &&
                      <Button
                        variant="contained"
                        className="no-text-transform custom-button-header"
                        style={{marginRight: 10}}
                        onClick={onClick}
                        disabled={isDisabledButton}
                      >
                          Save
                      </Button>
                    }

                </Grid>
            </Grid>
        </Box>
    );
};