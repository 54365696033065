import React from "react";
import {Box, Button, Grid, TextField} from "@mui/material";
import {Icon} from '@vacasa/react-components-lib';
import {HeaderClassic} from "../../../components/HeaderClassic/HeaderClassic";
import {useEmailValidatorView} from "./EmailValidatorView.hooks";
import "./EmailValidatorView.scss";
const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const EmailValidatorView = () => {
    const {
        isLoading,
        setIsLoading,
        validate,
        validation,
        email,
        setEmail,
        timeout,
        setTimeout,
        isEmailValid,
        setIsEmailValid
    } = useEmailValidatorView();


    const onClickSearch = async () => {
        setIsLoading(true);
        const isInputValidEmail = email !== "" && EMAIL_PATTERN.test(email)
        setIsEmailValid(isInputValidEmail);

        if(isInputValidEmail) {
            await validate();
        }

        setIsLoading(false)
    }

    const getIconName = (status: string) => {
        switch(status) {
            case "200":
                return (<Icon.CheckCircle className={"green-icon"} height={24} width={24}/>);
            case "210":
            case "220":
                return (<Icon.AlertTriangle className={"orange-icon"} height={24} width={24}/>);
            default:
                return (<Icon.AlertOctagon className={"red-icon"} height={24} width={24}/>);
        }
    }

    return (
        <Box style={{paddingTop: "20px"}}>
            <HeaderClassic title={"Email validator"}/>
            <Box className="body-box">
                <Box>
                    <Grid container>
                        <Grid item xs={5} md={5} className={"input-padding"}>
                            <TextField
                              id="email"
                              label="Add Email to Validate"
                              size="small"
                              variant="outlined"
                              value={email}
                              onChange={(event: any) => setEmail(event.target.value)}
                              error={!isEmailValid && email !== ""}
                              disabled={isLoading}
                              fullWidth
                            ></TextField>
                        </Grid>
                        <Grid item xs={4} md={4} className={"input-padding"}>
                            <TextField
                              id="timeout"
                              label="Custom Timeout"
                              size="small"
                              variant="outlined"
                              value={timeout}
                              onChange={(event: any) => setTimeout(!isNaN(+event.target.value) ? +event.target.value : timeout )}
                              disabled={isLoading}
                              fullWidth
                            ></TextField>
                        </Grid>
                        <Grid item xs={3} md={3} className={"input-padding"}>
                            <Button
                              className="no-text-transform custom-button-height custom-button"
                              onClick={onClickSearch}
                              variant="outlined"
                              style={{float: "right"}}
                              disabled={isLoading}
                              fullWidth>
                                <b>Validate email</b>
                            </Button>
                        </Grid>
                    </Grid>
                    <Box className="box-line-email"></Box>
                </Box>
                { validation &&
                    <div>
                        <Box className="header-classic">
                            <Grid container>
                                <Grid item xs={3} md={3}>
                                    <Box className="header-classic-box sub-title">
                                        <b>Status</b>
                                    </Box>
                                </Grid>
                                <Grid item xs={9} md={9}>
                                    <Box className="header-classic-box sub-title">
                                        <b>Description</b>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className="body-box">
                            <Grid container>
                                <Grid item xs={3} md={3} className={"input-padding"}>
                                    <Box className="header-classic-box sub-title">
                                        {validation.status}
                                    </Box>
                                </Grid>
                                <Grid item xs={9} md={9} className={"input-padding"}>
                                    <Box className="header-classic-box sub-title">
                                        {validation.description} {getIconName(validation.status)}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                }
            </Box>
        </Box>
    );
};