import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "./App.css";
import {Box, ThemeProvider} from "@mui/material";
import ConditionBlocklyView from "./views/tx/ConditionBlocklyView/ConditionBlocklyView";
import theme from "./theme";
import {SendgridManagerView} from "./views/sendgrid-manager/SendgridManagerView/SendgridManagerView";
import {EmailValidatorView} from "./views/cx/EmailValidatorView/EmailValidatorView";

export const App = () => {
    const containerHeight = document.documentElement.clientHeight;
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Box sx={{display: "flex", flexDirection: "column", height: "100vh", width: "100vw"}}>
                    <Box sx={{display: "flex", flex: 1, overflowY: "auto", height: containerHeight}}>
                        <Box sx={{display: "flex", flex: 1, flexDirection: "column"}}>
                            <Routes>
                                <Route path="/tx/condition" element={<ConditionBlocklyView />} />
                                <Route path="/sendgrid-manager" element={<SendgridManagerView />} />
                                <Route path="/email-validator" element={<EmailValidatorView />} />
                            </Routes>
                        </Box>
                    </Box>
                </Box>
            </BrowserRouter>
        </ThemeProvider>
    );
}