import * as Blockly from "blockly/core";
import PlusButtonField from "../fields/PlusButtonField";
import MinusButtonField from "../fields/MinusButtonField";
import PlusMinusMutator from "../mutator/plusMinusMutator";
import NamedConditionMutator from "../mutator/namedConditionMutator";
import { MenuOption } from "blockly/core";
import { ELEMENT, ELEMENT_, OPERATION } from "../utils/constants";
import PlusMinusObjectMutator from "../mutator/plusMinusObjectMutator";

const plusMinusMutatorFunctions = {
    saveExtraState: PlusMinusMutator.saveExtraState,
    loadExtraState: PlusMinusMutator.loadExtraState,
    mutationToDom: PlusMinusMutator.mutationToDom,
    domToMutation: PlusMinusMutator.domToMutation,
    updateShape_: PlusMinusMutator.updateShape_,
    plus: PlusMinusMutator.plus,
    minus: PlusMinusMutator.minus,
    addPart_: PlusMinusMutator.addPart_,
    removePart_: PlusMinusMutator.removePart_,
    length: PlusMinusMutator.length,
};

export const plusMinusMutatorFunctionsNamedConditions = {
    saveExtraState: NamedConditionMutator.saveExtraState,
    loadExtraState: NamedConditionMutator.loadExtraState,
    mutationToDom: NamedConditionMutator.mutationToDom,
    domToMutation: NamedConditionMutator.domToMutation,
    updateShape_: NamedConditionMutator.updateShape_,
    newInputs: NamedConditionMutator.newInputs,
    addPart_: NamedConditionMutator.addPart_,
    removePart_: NamedConditionMutator.removePart_,
    itemCount_: NamedConditionMutator.itemCount_
};

// MAIN
Blockly.Blocks["start"] = {
    init: function () {
        this.setColour("#0E3348");
        this.setOutput(true);
        this.appendValueInput(ELEMENT)
            .appendField("Init");
        this.setInputsInline(false);
        this.setDeletable(false);
    },
};

Blockly.Blocks["if"] = {
    init: function () {
        this.setColour("#007F90");
        this.setOutput(true, [ELEMENT]);

        this.appendDummyInput()
            .appendField("if")
            .appendField(new PlusButtonField("+"))
            .appendField(new MinusButtonField("-"));

        this.appendValueInput(`${ELEMENT_}base_0`);
        this.appendValueInput(`${ELEMENT_}base_1`);
        this.appendValueInput(`${ELEMENT_}base_2`);
        this.setInputsInline(false);
    },
    ...plusMinusMutatorFunctions
};

Blockly.Blocks["arrayOperations"] = {
    init: function () {
        this.setColour("#4D7080");
        this.setOutput(true, [ELEMENT]);

        const options1: Array<MenuOption> = [
            ["some", "some"],
            ["all", "all"],
            ["none", "none"],
        ];

        this.appendDummyInput().appendField(
            new Blockly.FieldDropdown(options1),
            OPERATION
        );
        this.appendValueInput(`${ELEMENT_}0`);
        this.appendValueInput(`${ELEMENT_}1`);
        this.setInputsInline(true);
        this.itemCount_ = 2;
    }
};

Blockly.Blocks["final"] = {
    init: function () {
        const options1: Array<MenuOption> = [
            ["true", "true"],
            ["false", "false"],
        ];
        const options2: Array<MenuOption> = [
            ["status: ", "status"],
            ["status_root", "status_root"],
        ];
        const options3: Array<MenuOption> = [
            ["stop", "stop"],
            ["again", "again"],
        ];

        this.setColour("#DDAD49");
        this.setOutput(true, [ELEMENT]);
        this.appendDummyInput()
            .appendField(new PlusButtonField("+"))
            .appendField(new MinusButtonField("-"));
        this.appendDummyInput()
            .appendField("send: ")
            .appendField(new Blockly.FieldDropdown(options1), "SEND")
            .appendField(",", "");
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown(options2), "NAME_STATUS")
            .appendField(": ");
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown(options3), "STATUS");

        this.setInputsInline(true);
    },
    ...plusMinusMutatorFunctions
};

// LOGIC
Blockly.Blocks["comparison"] = {
    init: function () {
        const options: Array<MenuOption> = [
            [">", ">"],
            [">=", ">="],
            ["<", "<"],
            ["<=", "<="],
        ];
        this.setColour("#4D7080");
        this.setOutput(true, [ELEMENT]);
        this.appendValueInput(`${ELEMENT_}0`);
        this.appendDummyInput().appendField(
            new Blockly.FieldDropdown(options),
            OPERATION
        );
        this.appendValueInput(`${ELEMENT_}1`);
        this.setInputsInline(true);
        this.itemCount_ = 2;
    },
};

Blockly.Blocks["logical"] = {
    init: function () {
        const options: Array<MenuOption> = [
            ["==", "=="],
            ["===", "==="],
            ["!=", "!="],
            ["!==", "!=="],
            ["!!", "!!"],
        ];
        this.setColour("#4D7080");
        this.setOutput(true, [ELEMENT]);
        this.appendDummyInput().appendField(
            new Blockly.FieldDropdown(options),
            OPERATION
        )
        .appendField(new PlusButtonField("+"))
        .appendField(new MinusButtonField("-"));

        this.setInputsInline(false);
    },
    ...plusMinusMutatorFunctions,
};

Blockly.Blocks["boolean"] = {
    init: function () {
        const options: Array<MenuOption> = [
            ["true", "true"],
            ["false", "false"]
        ];
        this.setColour("#0078AB");
        this.setOutput(true, [ELEMENT]);
        this.appendDummyInput().appendField(
            new Blockly.FieldDropdown(options),
            ELEMENT
        );
    },
};

Blockly.Blocks["between"] = {
    init: function () {
        const options: Array<MenuOption> = [
            ["between", "<"],
            ["between inclusive", "<="],
        ];
        this.setColour("#4D7080");
        this.setOutput(true, [ELEMENT]);

        this.appendValueInput(`${ELEMENT_}1`).appendField("is ");
        this.appendDummyInput().appendField(
            new Blockly.FieldDropdown(options),
            OPERATION
        );

        this.appendValueInput(`${ELEMENT_}0`);
        this.appendValueInput(`${ELEMENT_}2`);
        this.setInputsInline(true);
        this.itemCount_ = 3;
    },
};

Blockly.Blocks["not"] = {
    init: function () {
        this.appendValueInput(ELEMENT).appendField("not");
        this.setColour("#DF343A");
        this.setOutput(true, [ELEMENT]);
        this.setInputsInline(false);
    },
};

// WITH LIST
Blockly.Blocks["and"] = {
    init: function () {
        this.setColour("#9EB3B3");
        this.setOutput(true, [ELEMENT]);
        this.setInputsInline(false);
        this.appendDummyInput()
            .appendField("and")
            .appendField(new PlusButtonField("+"))
            .appendField(new MinusButtonField("-"));
    },
    ...plusMinusMutatorFunctions,
};

Blockly.Blocks["or"] = {
    init: function () {
        this.setColour("#99A98A");
        this.setOutput(true, [ELEMENT]);
        this.setInputsInline(false);
        this.appendDummyInput()
            .appendField("or")
            .appendField(new PlusButtonField("+"))
            .appendField(new MinusButtonField("-"));
    },
    ...plusMinusMutatorFunctions,
};

Blockly.Blocks["cat"] = {
    init: function () {
        this.setColour("#4D7080");
        this.setOutput(true, [ELEMENT]);
        this.setInputsInline(false);
        this.appendDummyInput()
            .appendField("cat")
            .appendField(new PlusButtonField("+"))
            .appendField(new MinusButtonField("-"));
    },
    ...plusMinusMutatorFunctions,
};

Blockly.Blocks["maxmin"] = {
    init: function () {
        this.setColour("#4D7080");
        this.setOutput(true, [ELEMENT]);
        this.setInputsInline(true);

        const options: Array<MenuOption> = [
            ["max", "max"],
            ["min", "min"]
        ];

        this.appendDummyInput().appendField(new Blockly.FieldDropdown(options), ELEMENT);

        this.appendDummyInput()
            .appendField(new PlusButtonField("+"))
            .appendField(new MinusButtonField("-"));
    },
    ...plusMinusMutatorFunctions,
};

Blockly.Blocks["substr"] = {
    init: function () {
        this.setColour("#4D7080");
        this.setOutput(true, [ELEMENT]);
        this.setInputsInline(true);
        this.appendDummyInput()
            .appendField("substr")
            .appendField(new PlusButtonField("+", null, null, 1))
            .appendField(" ")
            .appendField(new MinusButtonField("-"));

        this.appendValueInput(`${ELEMENT_}base_0`);
        this.appendValueInput(`${ELEMENT_}base_1`);
    },
    ...plusMinusMutatorFunctions,
};

Blockly.Blocks["lodash"] = {
    init: function () {
        this.setColour("#6BA342");
        this.setOutput(true, [ELEMENT]);
        this.setInputsInline(true);

        this.appendDummyInput()
            .appendField("lodash.")
            .appendField(new Blockly.FieldTextInput(""), ELEMENT)
            .appendField(new PlusButtonField("+"))
            .appendField(new MinusButtonField("-"));
    },
    ...plusMinusMutatorFunctions,
};

Blockly.Blocks["object"] = {
    init: function () {
        this.setColour("#0078AB");
        this.setOutput(true, [ELEMENT]);
        this.setInputsInline(false);
        this.appendDummyInput()
            .appendField("object")
            .appendField(new PlusButtonField("+"))
            .appendField(new MinusButtonField("-"));
    },
    saveExtraState: PlusMinusObjectMutator.saveExtraState,
    loadExtraState: PlusMinusObjectMutator.loadExtraState,
    mutationToDom: PlusMinusObjectMutator.mutationToDom,
    domToMutation: PlusMinusObjectMutator.domToMutation,
    updateShape_: PlusMinusObjectMutator.updateShape_,
    plus: PlusMinusObjectMutator.plus,
    minus: PlusMinusObjectMutator.minus,
    addPart_: PlusMinusObjectMutator.addPart_,
    removePart_: PlusMinusObjectMutator.removePart_,
    length: PlusMinusObjectMutator.length,
};

// VARS
Blockly.Blocks["string"] = {
    init: function () {
        this.setColour("#0078AB");
        this.setOutput(true, [ELEMENT]);

        this.appendDummyInput()
            .appendField("string")
            .appendField(new Blockly.FieldTextInput(""), ELEMENT);
    },
};

Blockly.Blocks["number"] = {
    init: function () {
        this.setColour("#0078AB");
        this.setOutput(true, [ELEMENT]);
        this.appendDummyInput()
            .appendField("number")
            .appendField(new Blockly.FieldNumber(0), ELEMENT);
    },
};

Blockly.Blocks["var"] = {
    init: function () {
        this.setColour("#0078AB");
        this.setOutput(true, [ELEMENT]);

        this.appendDummyInput()
            .appendField("var: ")
            .appendField(new Blockly.FieldTextInput(""), ELEMENT);
    },
};

Blockly.Blocks["keyValue"] = {
    init: function () {
        this.setColour("#0078AB");
        this.setOutput(true, [ELEMENT]);
        this.setInputsInline(true);
        this.appendDummyInput()
            .appendField(new Blockly.FieldTextInput(""), "KEY")
            .appendField(" : ");

        this.appendDummyInput()
            .appendField(new Blockly.FieldTextInput(""), ELEMENT);
    },
};

Blockly.Blocks["array"] = {
    init: function () {
        this.setColour("#0078AB");
        this.setOutput(true, [ELEMENT]);
        this.setInputsInline(true);

        this.appendDummyInput()
            .appendField("array")
            .appendField(new PlusButtonField("+"))
            .appendField(new MinusButtonField("-"));
    },
    ...plusMinusMutatorFunctions,
};

// Others
Blockly.Blocks["in"] = {
    init: function () {
        this.setColour("#4D7080");
        this.setOutput(true, [ELEMENT]);
        this.appendValueInput(`${ELEMENT_}0`).appendField("is");
        this.appendValueInput(`${ELEMENT_}1`).appendField("in");
        this.setInputsInline(true);
        this.itemCount_ = 2;
    },
};