import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import {SendgridGroup} from "../../types/sendgridManager";
import {Dispatch, SetStateAction} from "react";

function not(a: Array<SendgridGroup>, b: Array<SendgridGroup>) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: Array<SendgridGroup>, b: Array<SendgridGroup>) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export interface IManagerMuiProps {
    left: Array<SendgridGroup>;
    setLeft: Dispatch<SetStateAction<Array<SendgridGroup>>>;
    right: Array<SendgridGroup>;
    setRight: Dispatch<SetStateAction<Array<SendgridGroup>>>;
    isLoadingOrIsSaving: boolean;
}
export const SendgridGroupsList: React.FC<IManagerMuiProps> = ({left, setLeft, right, setRight, isLoadingOrIsSaving}) => {
    const [checked, setChecked] = React.useState<Array<SendgridGroup>>([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: SendgridGroup) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items: readonly SendgridGroup[], name: string) => (
        <Box>
            <p style={{paddingBottom: "10px"}}>{name} ({items.length})</p>
            <Paper sx={{ width: 400, height: 400, overflow: 'auto' }}>
                <List dense component="div" role="list">
                    {items.map((value: SendgridGroup) => {
                        const labelId = `transfer-list-item-${value.id}-label`;
                        return (
                            <ListItem
                                key={value.id}
                                role="listitem"
                                button
                                onClick={handleToggle(value)}
                                disabled={isLoadingOrIsSaving}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value.name} (${value.id})`} />
                            </ListItem>
                        );
                    })}
                </List>
            </Paper>
        </Box>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList(left, "Unsubscribed")}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0 || isLoadingOrIsSaving}
                        aria-label="move all right"
                    >
                        {'>>'}
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0 || isLoadingOrIsSaving}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0 || isLoadingOrIsSaving}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0 || isLoadingOrIsSaving}
                        aria-label="move all left"
                    >
                        {'<<'}
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList(right, "Subscribed")}</Grid>
        </Grid>
    );
}