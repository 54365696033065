import React from "react";
import {Box, Grid} from "@mui/material";
import "./../HeaderClassic/HeaderClassic.scss";
import {Icon, Button, ThemeProvider} from '@vacasa/react-components-lib';
import theme from "@vacasa/react-components-lib/lib/themes/default";

export interface IHeaderConditionProps {
    onClick: () => void;
    resetToPreviousCode: () => void;
}
export const HeaderModalCondition: React.FC<IHeaderConditionProps> = ({onClick, resetToPreviousCode}) => {
    return (
        <Box className="header-classic">
            <Grid container>
                <Grid item md={6}>
                    <Box style={{display: "flex"}}>
                        <b style={{fontSize: 18, marginTop: 10, marginLeft: 20}}>CONDITIONAL BLOCK EDITOR (BLOCKLY)</b>
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <Box style={{ display: "flex", justifyContent: "end"}}>
                        <ThemeProvider theme={theme}>
                            <Button onClick={resetToPreviousCode} variant="info" customClass="buttons">
                                Back to Draft <Icon.RotateCCW style={{marginBottom: 3, width: 18, height: 18, marginLeft: 5}} />
                            </Button>
                            <Button onClick={onClick} variant="info">
                                Close Block Editor <Icon.XCircle style={{marginBottom: 3, width: 20, height: 20, marginLeft: 5}} />
                             </Button>
                        </ThemeProvider>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};