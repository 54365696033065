export class FrontendConfiguration {
  static readonly LOCAL: string = "local";
  static readonly STAGING: string = "staging";
  static readonly PRODUCTION: string = "production";

  public static environment(): string {
    if (FrontendConfiguration.appUrl()?.includes("localhost")) {
      return this.LOCAL;
    } else if (FrontendConfiguration.appUrl()?.includes("vacasastage.")) {
      return this.STAGING;
    } else if (FrontendConfiguration.appUrl()?.includes("vacasa.")) {
      return this.PRODUCTION;
    }

    return this.LOCAL;
  }

  public static isLocalEnv(): boolean {
    return FrontendConfiguration.appUrl()?.includes("localhost") ?? false;
  }

  public static appUrl() {
    const {REACT_APP_SPA_URL} = process.env;
    return REACT_APP_SPA_URL;
  }

  public static apiUrl() {
    const {REACT_APP_API_URL} = process.env;
    return REACT_APP_API_URL;
  }

  public static getLegacyAngularAppUrl(): string {
    const {REACT_APP_LEGACY_ANGULAR_APP_URL} = process.env;
    return REACT_APP_LEGACY_ANGULAR_APP_URL ?? "";
  }

  public static implicitFlowConfig() {
    const {REACT_APP_IDP_CLIENT_ID, REACT_APP_IDP_AUDIENCE, REACT_APP_IDP_SCOPES, REACT_APP_IDP_REDIRECT_URI} = process.env;
    let environment;
    switch (this.environment()) {
      case "staging":
        environment = "stage" as "stage";
        break;
      case "production":
        environment = "prod" as "prod";
        break;
      default:
        environment = "stage" as "stage";
        break;
    }
    return {
      clientId: REACT_APP_IDP_CLIENT_ID ?? "",
      audience: REACT_APP_IDP_AUDIENCE ?? "",
      scopes: REACT_APP_IDP_SCOPES ?? "",
      directoryHint: "onelogin",
      env: environment as any,
      redirectUrl: REACT_APP_IDP_REDIRECT_URI ?? "",
    };
  }
}
