import * as Blockly from "blockly/core";
import {getExtraBlockState} from "../helpers/serializationHelper";

export default class PlusButtonField extends Blockly.Field {
    SERIALIZABLE = true
    MAX: any;

    constructor(name: string, validator?: any, config?: any, max?: number) {
        super(name);
        this.MAX = max;
    }

    static fromJson(options: any): any {
        return new this(options["text"]);
    }

    showEditor_(e: any): void {
        super.showEditor_(e);
        this.onClick();
    }

    onClick(): void {
        const block = this.getSourceBlock() as any;
        if (block.isInFlyout) {
            return;
        }

        Blockly.Events.setGroup(true);
        const oldExtraState = getExtraBlockState(block);
        block.plus(this.MAX);
        const newExtraState = getExtraBlockState(block);

        if (oldExtraState !== newExtraState) {
          Blockly.Events.fire(
              new Blockly.Events.BlockChange(
                  block,
                  "mutation",
                  null,
                  oldExtraState,
                  newExtraState
              )
          );
        }
        Blockly.Events.setGroup(false);
    }
}

Blockly.fieldRegistry.register("field_plus_button", PlusButtonField);
