import React, {Dispatch, SetStateAction, useEffect} from "react";
import {Box, TextField, Button, Grid} from "@mui/material";
import "./EmailSearch.scss";
import {canAccess} from "../../utils/permissions";
const EMAIL_PATTERN =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export interface IEmailSearchProps {
    email: string;
    setEmail: Dispatch<SetStateAction<string>>;
    onClickSearch: () => void;
    isSavingOrLoading: boolean;
    setIsEmailValid: Dispatch<SetStateAction<boolean>>;
    isEmailValid: boolean;
}
export const EmailSearch: React.FC<IEmailSearchProps> = ({onClickSearch, email, setEmail, isSavingOrLoading, setIsEmailValid, isEmailValid}) => {
    const canAccessToSM = canAccess("sm.full");

    useEffect(() => {
        setIsEmailValid(!!email && EMAIL_PATTERN.test(email));
        // eslint-disable-next-line
    }, [email]);

    return (
        <Box>
            <Grid container>
                <Grid item xs={2} md={2}>
                    <Box className="input-label" style={{marginTop: 10}}>Search:</Box>
                </Grid>
                <Grid item xs={8} md={8}>
                    <TextField
                        placeholder="Enter user email"
                        size="small"
                        value={email}
                        onChange={(event: any) => setEmail(event.target.value)}
                        error={!isEmailValid && email !== ""}
                        disabled={!canAccessToSM}
                        fullWidth
                    ></TextField>
                </Grid>
                <Grid item xs={2} md={2}>
                    <Button className="no-text-transform custom-button-height" onClick={onClickSearch} variant="outlined" style={{float: "right"}} disabled={!isEmailValid || isSavingOrLoading}>Search</Button>
                </Grid>
            </Grid>
            <Box className="box-line-email"></Box>
        </Box>
    );
};