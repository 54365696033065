import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import { AlertMessage, ThemeProvider } from "@vacasa/react-components-lib";
import theme from "@vacasa/react-components-lib/lib/themes/default";
import "./Alert.scss";

interface IAlertProps {
    type: string;
    message: string;
    setMessage: Dispatch<SetStateAction<any>>;
    noTimeout?: boolean;
}
export const Alert: React.FC<IAlertProps> = ({ type, message, setMessage, noTimeout, ...props}) => {
    const [alert, setAlert] = useState<boolean>(true);

    useEffect((): (() => void) => {
        setAlert(true);
        const timeId = setTimeout(() => {
            setAlert(false);

            if (type === "success") {
                setMessage(false);
            } else {
                setMessage("");
            }

        }, 6000);

        return () => {
            clearTimeout(timeId);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            {alert && !noTimeout &&
                <ThemeProvider theme={theme}>
                    <AlertMessage type={type as any} text={message} height="small"/>
                </ThemeProvider>
            }
            {
                noTimeout &&
                <ThemeProvider theme={theme}>
                    <AlertMessage type={type as any} text={message} height="small" customClass="alert-class">{props.children}</AlertMessage>
                </ThemeProvider>
            }
        </React.Fragment>
    );
};
